<script setup>
import Dropdown from "primevue/dropdown";
import { ref, reactive } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import router from "../router";
import cookie from "../cookie";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import logo from "../assets/leyu-logo-w.png";
import User from "../api/user";
import firebase from "firebase";
import { useToast } from "primevue/usetoast";

const toast = useToast();

let userApi = new User();

let cookieSetting = new cookie();
const i18n = useI18n();
const store = useStore();
defineProps({
  //   video_stream: String,
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      // toast.add({
      //   severity: "warn",
      //   summary: "Warn Message",
      //   detail: res.data.error_text,
      //   life: 3000,
      // });
      // store.commit("set_login", "");
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function Logout() {
  userApi.logoutUser().then((res) => {
    if (res.data.error_code == 0) {
      CheckUser();
      routerSwitch("/");
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function langSwitch() {
  cookieSetting.doCookieSetup("lang", store.state.lang);
  i18n.locale.value = store.state.lang;
}
function routerSwitch(url) {
  router.push(url);
}
const data = reactive({
  lang: [
    { name: "中文", code: "cn" },
    { name: "English", code: "en" },
  ],
  WarehouseRole: [1, 2, 3, 4],
  OrderRole: [11, 12, 13, 14, 15, 16, 17, 18, 19],
  warehouse_items:
    store.state.user.role == 1 || store.state.user.role == 4
      ? [
          {
            label: i18n.$t("NavBar").Title,
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: i18n.$t("Material").Title,
                icon: "pi pi-fw pi-pencil",
                to: "/",
              },
              {
                label: i18n.$t("Stock").SettingFactory,
                icon: "pi pi-fw pi-pencil",
                to: "setting-factory",
              },
              {
                label: i18n.$t("Stock").SettingWarehouse,
                icon: "pi pi-fw pi-pencil",
                to: "setting-warehouse",
              },
              {
                label: i18n.$t("Stock").SettingStockLocation,
                icon: "pi pi-fw pi-pencil",
                to: "setting-stock-location",
              },
              {
                label: i18n.$t("Stock").SettingWarehouseSupplier,
                icon: "pi pi-fw pi-pencil",
                to: "setting-supplier",
              },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
          {
            label: i18n.$t("Stock").WarehouseCheck,
            icon: "pi pi-fw pi-book",
            to: "warehouse-check",
          },
          {
            label: i18n.$t("NavBar").Transfer,
            icon: "pi pi-fw pi-reply",
            items: [
              {
                label: i18n.$t("Stock").TransferAudit,
                icon: "pi pi-fw pi-book",
                to: "transfer-audit",
              },
              {
                label: i18n.$t("Stock").TransferLog,
                icon: "pi pi-fw pi-book",
                to: "transfer-log",
              },
            ],
          },
          // {
          //   label: i18n.$t("Stock").WarehousePurchaseOrder,
          //   icon: "pi pi-fw pi-book",
          //   to: "warehouse-order",
          // },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              // {
              //   label: i18n.$t("Stock").TotalTitle,
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "total",
              // },
              {
                label: i18n.$t("Stock").TotalLastTitle,
                icon: "pi pi-fw pi-chart-bar",
                to: "last-total",
              },
              // {
              //   label: i18n.$t("Stock").StockSave,
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "stock-save",
              // },
            ],
          },
        ]
      : [
          {
            label: i18n.$t("NavBar").Title,
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: i18n.$t("Material").Title,
                icon: "pi pi-fw pi-pencil",
                to: "/",
              },
              {
                label: i18n.$t("Stock").SettingStockLocation,
                icon: "pi pi-fw pi-pencil",
                to: "setting-stock-location",
              },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
          {
            label: i18n.$t("Stock").WarehouseCheck,
            icon: "pi pi-fw pi-book",
            to: "warehouse-check",
          },
          // {
          //   label: i18n.$t("Stock").TransferLog,
          //   icon: "pi pi-fw pi-pencil",
          //   to: "transfer-log",
          // },
          {
            label: i18n.$t("NavBar").Transfer,
            icon: "pi pi-fw pi-reply",
            items: [
              // {
              //   label: i18n.$t("Stock").TransferAudit,
              //   icon: "pi pi-fw pi-book",
              //   to: "transfer-audit",
              // },
              {
                label: i18n.$t("Stock").TransferLog,
                icon: "pi pi-fw pi-book",
                to: "transfer-log",
              },
            ],
          },
          // {
          //   label: i18n.$t("Stock").WarehousePurchaseOrder,
          //   icon: "pi pi-fw pi-book",
          //   to: "warehouse-order",
          // },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              // {
              //   label: i18n.$t("Stock").TotalTitle,
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "total",
              // },
              {
                label: i18n.$t("Stock").TotalLastTitle,
                icon: "pi pi-fw pi-chart-bar",
                to: "last-total",
              },
              // {
              //   label: i18n.$t("Stock").StockSave,
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "stock-save",
              // },
            ],
          },
        ],
  items:
    store.state.user.id == 216 ||
    store.state.user.id == 221 ||
    store.state.user.id == 222 ||
    store.state.user.id == 223 ||
    store.state.user.id == 224 ||
    store.state.user.id == 225
      ? [
          {
            label: i18n.$t("NavBar").Board,
            icon: "pi pi-fw pi-chart-line",
            items: [
              {
                label: i18n.$t("NavBar").Schedule,
                icon: "pi pi-fw pi-user",
                to: "schedule",
              },
              {
                label: i18n.$t("NavBar").ScheduleHistory,
                icon: "pi pi-fw pi-user",
                to: "schedule-history",
              },
            ],
          },
        ]
      : store.state.user.id == 219 ||
        store.state.user.id == 220 ||
        store.state.user.id == 15 ||
        store.state.user.id == 18 ||
        store.state.user.id == 19 ||
        store.state.user.id == 22 ||
        store.state.user.id == 23 ||
        store.state.user.id == 24 ||
        store.state.user.id == 27 ||
        store.state.user.id == 29 ||
        store.state.user.id == 30 ||
        store.state.user.id == 31 ||
        store.state.user.id == 33 ||
        store.state.user.id == 36 ||
        store.state.user.id == 37 ||
        store.state.user.id == 38 ||
        store.state.user.id == 40 ||
        store.state.user.id == 58 ||
        store.state.user.id == 195 ||
        store.state.user.id == 196 ||
        store.state.user.id == 200 ||
        store.state.user.id == 208 ||
        store.state.user.id == 210
      ? [
          {
            label: i18n.$t("NavBar").Order,
            icon: "pi pi-fw pi-book",
            items: [
              {
                label: i18n.$t("NavBar").Order,
                icon: "pi pi-fw pi-book",
                to: "order",
              },
              {
                label: i18n.$t("NavBar").OrderContentDetail,
                icon: "pi pi-fw pi-book",
                to: "order-content-detail",
              },
              {
                label: i18n.$t("NavBar").OrderDetail,
                icon: "pi pi-fw pi-file-pdf",
                to: "order-detail",
              },
              {
                label: i18n.$t("NavBar").WarehouseTemplate,
                icon: "pi pi-fw pi-book",
                to: "warehouse-template",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Station,
            icon: "pi pi-fw pi-check-circle",
            items: [
              {
                label: i18n.$t("NavBar").Station,
                icon: "pi pi-fw pi-check-circle",
                to: "order-station",
              },
              {
                label: i18n.$t("NavBar").StationDefine,
                icon: "pi pi-fw pi-pencil",
                to: "station-define",
              },
              {
                label: i18n.$t("NavBar").StationProcessCombination,
                icon: "pi pi-fw pi-pencil",
                to: "station-combination",
              },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
        ]
      : store.state.user.id == 201 ||
        store.state.user.id == 9 ||
        store.state.user.id == 5
      ? [
          {
            label: i18n.$t("NavBar").Board,
            icon: "pi pi-fw pi-chart-line",
            items: [
              {
                label: i18n.$t("NavBar").Board,
                icon: "pi pi-fw pi-chart-line",
                to: "board-room",
              },
              {
                label: i18n.$t("NavBar").StationCutOutSewNotIn,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-cut",
              },
              {
                label: i18n.$t("NavBar").StationProgress,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-progress",
              },
              {
                label: i18n.$t("NavBar").Schedule,
                icon: "pi pi-fw pi-user",
                to: "schedule",
              },
              {
                label: i18n.$t("NavBar").ScheduleHistory,
                icon: "pi pi-fw pi-user",
                to: "schedule-history",
              },
              {
                label: i18n.$t("NavBar").OrderLine,
                icon: "pi pi-fw pi-user",
                to: "order-line",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalary,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalaryLine,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary-line",
              },
              {
                label: i18n.$t("NavBar").OrderLinePrice,
                icon: "pi pi-fw pi-user",
                to: "orderline-price",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Title,
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: i18n.$t("Agent").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-agent",
              },
              {
                label: i18n.$t("Cloth").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cloth",
              },
              {
                label: i18n.$t("CmtFactory").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cmtFactory",
              },
              {
                label: i18n.$t("Customer").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-customer",
              },
              {
                label: i18n.$t("Item").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-item",
              },
              {
                label: i18n.$t("ProductGroup").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-product-group",
              },
              {
                label: i18n.$t("Supplier").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-supplier",
              },
              {
                label: i18n.$t("SizeType").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-type",
              },
              {
                label: i18n.$t("SizeItem").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-item",
              },
              {
                label: i18n.$t("NavBar").Process,
                icon: "pi pi-fw pi-pencil",
                to: "process",
              },
              {
                label: i18n.$t("NavBar").BoxTemplate,
                icon: "pi pi-fw pi-pencil",
                to: "box-template",
              },
              {
                label: i18n.$t("NavBar").LocalEmployer,
                icon: "pi pi-fw pi-pencil",
                to: "local-employer",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Order,
            icon: "pi pi-fw pi-book",
            items: [
              {
                label: i18n.$t("NavBar").Order,
                icon: "pi pi-fw pi-book",
                to: "order",
              },
              {
                label: i18n.$t("NavBar").OrderContentDetail,
                icon: "pi pi-fw pi-book",
                to: "order-content-detail",
              },
              {
                label: i18n.$t("NavBar").OrderDetail,
                icon: "pi pi-fw pi-file-pdf",
                to: "order-detail",
              },
              {
                label: i18n.$t("NavBar").OrderAudit,
                icon: "pi pi-fw pi-book",
                to: "order-audit",
              },
              {
                label: i18n.$t("NavBar").WarehouseTemplate,
                icon: "pi pi-fw pi-book",
                to: "warehouse-template",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Station,
            icon: "pi pi-fw pi-check-circle",
            items: [
              {
                label: i18n.$t("NavBar").Station,
                icon: "pi pi-fw pi-check-circle",
                to: "order-station",
              },
              {
                label: i18n.$t("NavBar").StationDefine,
                icon: "pi pi-fw pi-pencil",
                to: "station-define",
              },
              {
                label: i18n.$t("NavBar").StationProcessCombination,
                icon: "pi pi-fw pi-pencil",
                to: "station-combination",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").StationHistory,
            icon: "pi pi-fw pi-list",
            to: "station-history",
          },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              {
                label: i18n.$t("NavBar").StationReport,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-completed",
              },
              {
                label: i18n.$t("NavBar").StationWork,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-work",
              },
              {
                label: i18n.$t("NavBar").StationWorkByHistory,
                icon: "pi pi-fw pi-chart-bar",
                to: "work-history",
              },
              {
                label: i18n.$t("NavBar").StationEstimated,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-estimated",
              },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
          {
            label: i18n.$t("NavBar").User,
            icon: "pi pi-fw pi-user",
            items: [
              {
                label: i18n.$t("NavBar").User,
                icon: "pi pi-fw pi-user",
                to: "user",
              },
            ],
          },
        ]
      : store.state.user.role == 11
      ? [
          {
            label: i18n.$t("NavBar").Board,
            icon: "pi pi-fw pi-chart-line",
            items: [
              {
                label: i18n.$t("NavBar").Board,
                icon: "pi pi-fw pi-chart-line",
                to: "board-room",
              },
              {
                label: i18n.$t("NavBar").StationCutOutSewNotIn,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-cut",
              },
              {
                label: i18n.$t("NavBar").StationProgress,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-progress",
              },
              {
                label: i18n.$t("NavBar").Schedule,
                icon: "pi pi-fw pi-user",
                to: "schedule",
              },
              {
                label: i18n.$t("NavBar").ScheduleHistory,
                icon: "pi pi-fw pi-user",
                to: "schedule-history",
              },
              {
                label: i18n.$t("NavBar").OrderLine,
                icon: "pi pi-fw pi-user",
                to: "order-line",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalary,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalaryLine,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary-line",
              },
              {
                label: i18n.$t("NavBar").OrderLinePrice,
                icon: "pi pi-fw pi-user",
                to: "orderline-price",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Title,
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: i18n.$t("Agent").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-agent",
              },
              {
                label: i18n.$t("Cloth").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cloth",
              },
              {
                label: i18n.$t("CmtFactory").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cmtFactory",
              },
              {
                label: i18n.$t("Customer").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-customer",
              },
              {
                label: i18n.$t("Item").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-item",
              },
              {
                label: i18n.$t("ProductGroup").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-product-group",
              },
              {
                label: i18n.$t("Supplier").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-supplier",
              },
              {
                label: i18n.$t("SizeType").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-type",
              },
              {
                label: i18n.$t("SizeItem").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-item",
              },
              {
                label: i18n.$t("NavBar").Process,
                icon: "pi pi-fw pi-pencil",
                to: "process",
              },
              {
                label: i18n.$t("NavBar").BoxTemplate,
                icon: "pi pi-fw pi-pencil",
                to: "box-template",
              },
              {
                label: i18n.$t("NavBar").LocalEmployer,
                icon: "pi pi-fw pi-pencil",
                to: "local-employer",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Order,
            icon: "pi pi-fw pi-book",
            items: [
              {
                label: i18n.$t("NavBar").Order,
                icon: "pi pi-fw pi-book",
                to: "order",
              },
              {
                label: i18n.$t("NavBar").OrderContentDetail,
                icon: "pi pi-fw pi-book",
                to: "order-content-detail",
              },
              {
                label: i18n.$t("NavBar").OrderDetail,
                icon: "pi pi-fw pi-file-pdf",
                to: "order-detail",
              },
              {
                label: i18n.$t("NavBar").WarehouseTemplate,
                icon: "pi pi-fw pi-book",
                to: "warehouse-template",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Station,
            icon: "pi pi-fw pi-check-circle",
            items: [
              {
                label: i18n.$t("NavBar").Station,
                icon: "pi pi-fw pi-check-circle",
                to: "order-station",
              },
              {
                label: i18n.$t("NavBar").StationDefine,
                icon: "pi pi-fw pi-pencil",
                to: "station-define",
              },
              {
                label: i18n.$t("NavBar").StationProcessCombination,
                icon: "pi pi-fw pi-pencil",
                to: "station-combination",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").StationHistory,
            icon: "pi pi-fw pi-list",
            to: "station-history",
          },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              {
                label: i18n.$t("NavBar").StationReport,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-completed",
              },
              {
                label: i18n.$t("NavBar").StationWork,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-work",
              },
              {
                label: i18n.$t("NavBar").StationWorkByHistory,
                icon: "pi pi-fw pi-chart-bar",
                to: "work-history",
              },
              {
                label: i18n.$t("NavBar").StationEstimated,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-estimated",
              },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
          {
            label: i18n.$t("NavBar").User,
            icon: "pi pi-fw pi-user",
            items: [
              {
                label: i18n.$t("NavBar").User,
                icon: "pi pi-fw pi-user",
                to: "user",
              },
            ],
          },
        ]
      : store.state.user.role == 12
      ? [
          // {
          //   label: i18n.$t("Stock").SettingStockLocation,
          //   icon: "pi pi-fw pi-pencil",
          //   to: "setting-stock-location",
          // },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
          {
            label: i18n.$t("Stock").WarehouseCheck,
            icon: "pi pi-fw pi-book",
            to: "warehouse-check",
          },
          // {
          //   label: i18n.$t("Stock").TransferLog,
          //   icon: "pi pi-fw pi-book",
          //   to: "transfer-log",
          // },
          {
            label: i18n.$t("NavBar").Transfer,
            icon: "pi pi-fw pi-reply",
            items: [
              {
                label: i18n.$t("Stock").TransferAudit,
                icon: "pi pi-fw pi-book",
                to: "transfer-audit",
              },
              {
                label: i18n.$t("Stock").TransferLog,
                icon: "pi pi-fw pi-book",
                to: "transfer-log",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              {
                label: i18n.$t("Stock").TotalLastTitle,
                icon: "pi pi-fw pi-chart-bar",
                to: "last-total",
              },
            ],
          },
        ]
      : [
          {
            label: i18n.$t("NavBar").Board,
            icon: "pi pi-fw pi-chart-line",
            items: [
              {
                label: i18n.$t("NavBar").Board,
                icon: "pi pi-fw pi-chart-line",
                to: "board-room",
              },
              {
                label: i18n.$t("NavBar").StationCutOutSewNotIn,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-cut",
              },
              {
                label: i18n.$t("NavBar").StationProgress,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-progress",
              },
              {
                label: i18n.$t("NavBar").Schedule,
                icon: "pi pi-fw pi-user",
                to: "schedule",
              },
              {
                label: i18n.$t("NavBar").ScheduleHistory,
                icon: "pi pi-fw pi-user",
                to: "schedule-history",
              },
              {
                label: i18n.$t("NavBar").OrderLine,
                icon: "pi pi-fw pi-user",
                to: "order-line",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalary,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary",
              },
              {
                label: i18n.$t("NavBar").OrderLineSalaryLine,
                icon: "pi pi-fw pi-user",
                to: "orderline-salary-line",
              },
              {
                label: i18n.$t("NavBar").OrderLinePrice,
                icon: "pi pi-fw pi-user",
                to: "orderline-price",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Title,
            icon: "pi pi-fw pi-pencil",
            items: [
              {
                label: i18n.$t("Agent").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-agent",
              },
              {
                label: i18n.$t("Cloth").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cloth",
              },
              {
                label: i18n.$t("CmtFactory").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-cmtFactory",
              },
              {
                label: i18n.$t("Customer").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-customer",
              },
              {
                label: i18n.$t("Item").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-item",
              },
              {
                label: i18n.$t("ProductGroup").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-product-group",
              },
              {
                label: i18n.$t("Supplier").Title,
                icon: "pi pi-fw pi-pencil",
                to: "order-supplier",
              },
              {
                label: i18n.$t("SizeType").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-type",
              },
              {
                label: i18n.$t("SizeItem").Title,
                icon: "pi pi-fw pi-pencil",
                to: "size-item",
              },
              {
                label: i18n.$t("NavBar").Process,
                icon: "pi pi-fw pi-pencil",
                to: "process",
              },
              {
                label: i18n.$t("NavBar").BoxTemplate,
                icon: "pi pi-fw pi-pencil",
                to: "box-template",
              },
              {
                label: i18n.$t("NavBar").LocalEmployer,
                icon: "pi pi-fw pi-pencil",
                to: "local-employer",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Order,
            icon: "pi pi-fw pi-book",
            items: [
              {
                label: i18n.$t("NavBar").Order,
                icon: "pi pi-fw pi-book",
                to: "order",
              },
              {
                label: i18n.$t("NavBar").OrderContentDetail,
                icon: "pi pi-fw pi-book",
                to: "order-content-detail",
              },
              {
                label: i18n.$t("NavBar").OrderDetail,
                icon: "pi pi-fw pi-file-pdf",
                to: "order-detail",
              },
              {
                label: i18n.$t("NavBar").WarehouseTemplate,
                icon: "pi pi-fw pi-book",
                to: "warehouse-template",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").Station,
            icon: "pi pi-fw pi-check-circle",
            items: [
              {
                label: i18n.$t("NavBar").Station,
                icon: "pi pi-fw pi-check-circle",
                to: "order-station",
              },
              {
                label: i18n.$t("NavBar").StationDefine,
                icon: "pi pi-fw pi-pencil",
                to: "station-define",
              },
              {
                label: i18n.$t("NavBar").StationProcessCombination,
                icon: "pi pi-fw pi-pencil",
                to: "station-combination",
              },
            ],
          },
          {
            label: i18n.$t("NavBar").StationHistory,
            icon: "pi pi-fw pi-list",
            to: "station-history",
          },
          {
            label: i18n.$t("NavBar").Report,
            icon: "pi pi-fw pi-chart-bar",
            items: [
              {
                label: i18n.$t("NavBar").StationReport,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-completed",
              },
              {
                label: i18n.$t("NavBar").StationWork,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-work",
              },
              {
                label: i18n.$t("NavBar").StationWorkByHistory,
                icon: "pi pi-fw pi-chart-bar",
                to: "work-history",
              },
              {
                label: i18n.$t("NavBar").StationEstimated,
                icon: "pi pi-fw pi-chart-bar",
                to: "station-estimated",
              },
              // {
              //   label: i18n.$t("NavBar").StationCutOutSewNotIn,
              //   icon: "pi pi-fw pi-chart-bar",
              //   to: "station-cut",
              // },
            ],
          },
          {
            label: i18n.$t("Stock").Title,
            icon: "pi pi-fw pi-box",
            items: [
              {
                label: i18n.$t("Stock").Title,
                icon: "pi pi-fw pi-box",
                to: "stock",
              },
              {
                label: i18n.$t("Stock").HistoryTitle,
                icon: "pi pi-fw pi-box",
                to: "history-stock",
              },
            ],
            // to: "stock",
          },
        ],
});
</script>

<template>
  <Toolbar key="1" class="toolbar">
    <template #start>
      <img alt="logo" :src="logo" class="p-mr-2 logo" />
    </template>

    <template #end>
      <Dropdown
        v-model="store.state.lang"
        :options="data.lang"
        @change="langSwitch"
        optionLabel="name"
        optionValue="code"
      />
      <Button
        class="p-button-danger p-ml-1"
        icon="pi pi-sign-out"
        iconPos="left"
        @click="Logout"
      ></Button>
    </template>
  </Toolbar>
  <Menubar
    v-if="data.WarehouseRole.includes(store.state.user.role)"
    :model="data.warehouse_items"
    style="
      position: fixed;
      top: 85px;
      right: 0px;
      z-index: 100;
      border-bottom: 1.5px solid;
      width: 100vw;
    "
  >
  </Menubar>
  <Menubar
    v-if="data.OrderRole.includes(store.state.user.role)"
    :model="data.items"
    style="
      position: fixed;
      top: 85px;
      right: 0px;
      z-index: 100;
      border-bottom: 1.5px solid;
      width: 100vw;
    "
  >
  </Menubar>
  <!-- <Toolbar
    v-if="data.OrderRole.includes(store.state.user.role)"
    key="2"
    class="toolbar_detail"
    style="padding-top: 8px !important; white-space: nowrap; overflow: scroll"
  >
    <template #start>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-agent')"
      >
        {{ i18n.$t("Agent").Title }}
      </div>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-cloth')"
      >
        {{ i18n.$t("Cloth").Title }}
      </div>

      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-cmtFactory')"
      >
        {{ i18n.$t("CmtFactory").Title }}
      </div>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-customer')"
      >
        {{ i18n.$t("Customer").Title }}
      </div>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-item')"
      >
        {{ i18n.$t("Item").Title }}
      </div>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-product-group')"
      >
        {{ i18n.$t("ProductGroup").Title }}
      </div>
      <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('order-supplier')"
      >
        {{ i18n.$t("Supplier").Title }}
      </div>
    </template>
  </Toolbar> -->
</template>

<style lang="scss">
.button {
  width: auto;
  cursor: pointer;
  border: 2px dotted;
}
.button:hover {
  color: rgb(199, 6, 6);
  border-bottom: 0.5rem solid;
}
.toolbar_detail {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 50px;
  left: 0px;
  /* overflow: hidden; */
  border-top: none !important;
  background: rgb(252, 252, 252) !important;
  border-bottom: 0.1rem solid !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 85px;
  z-index: 0;
}
.toolbar {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 85px;
  left: 0px;
  /* overflow: hidden; */
  background: rgb(28, 1, 102) !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 0;
  z-index: 99;
  .logo {
    position: fixed; /* Set the navbar to fixed position */
    top: 5;
    height: 65px;
  }
}
</style>
